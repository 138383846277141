import {AfterViewInit, Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {NbPopoverDirective} from '@nebular/theme';
import {HeaderFiltersStore} from '@store/load-forecast/header-filters.store';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {Unsubscribable} from '@core/interfaces/unsubscribable';

@Component({
    selector: 'ngx-numeric-range-control',
    templateUrl: './numeric-range-control.component.html',
    styleUrls: ['./numeric-range-control.component.scss'],
    styles: [
        `
            .flex {
                align-items: center;
                display: flex;
                justify-content: space-between;
                width: 100%;
            }

            .form-field {
                width: 47%;
            }

            label {
                font-family: Roboto, 'Helvetica Neue', sans-serif !important;
                font-weight: 500 !important;
                margin-bottom: 0.25rem;
            }
        `,
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NumericRangeControlComponent),
            multi: true,
        },
    ],
})
export class NumericRangeControlComponent
    extends Unsubscribable
    implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges
{
    @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;

    @Input() localDefault?: {min: string; max: string};
    globalDefault?: {min: string; max: string};

    isPopoverShown: boolean = false;

    inputControl: FormControl = new FormControl();
    minControl: FormControl = new FormControl();
    maxControl: FormControl = new FormControl();

    onChange(_: any) {}

    constructor(private headerFiltersStore: HeaderFiltersStore) {
        super();
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched() {}

    writeValue(value: any) {
        this.minControl.setValue(+value.min ?? '', {emitEvent: false});
        this.maxControl.setValue(+value.max ?? '', {emitEvent: false});
        this.setInputValues();
    }

    ngOnInit() {
        this.globalDefault = this.headerFiltersStore.getPeriodDefault();

        this.inputControl.valueChanges
            .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
            .subscribe((value: string) => {
                if (!value) {
                    this.onChange({min: '', max: ''});
                } else if (this.inputControl.status === 'VALID') {
                    this.onChange({min: this.minControl.value + '', max: this.maxControl.value + ''});
                }
            });
    }

    ngAfterViewInit() {
        this.popover?.nbPopoverShowStateChange.pipe(takeUntil(this.unsubscribe$)).subscribe(({isShown}) => {
            if (!isShown) {
                this.setInputValues();
                this.isPopoverShown = false;
            } else {
                this.isPopoverShown = true;
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.localDefault && changes.localDefault.currentValue) {
            setTimeout(() => {
                this.minControl.setValue(+this.localDefault?.min || '', {emitEvent: false});
                this.maxControl.setValue(+this.localDefault?.max || '');
            });
        }
    }

    private setInputValues() {
        this.formValueValidation();
        this.completeValues();
    }

    private formValueValidation() {
        // Validate min value or reset within appropriate boundaries
        const minVal = this.minControl.value;
        if (minVal !== null && minVal < +this.globalDefault?.min) {
            this.minControl.setValue(+this.globalDefault?.min, {emitEvent: false});
        }

        if (minVal !== null && minVal < +this.localDefault?.min) {
            this.minControl.setValue(+this.localDefault?.min, {emitEvent: false});
        }

        if (minVal !== null && minVal > +this.globalDefault?.max) {
            this.minControl.setValue(+this.globalDefault?.max, {emitEvent: false});
        }

        if (minVal !== null && minVal > +this.localDefault?.max) {
            this.minControl.setValue(+this.localDefault?.max, {emitEvent: false});
        }

        // Validate max value or reset within appropriate boundaries
        const maxValue = this.maxControl.value;
        if (maxValue !== null && maxValue < +this.globalDefault?.min) {
            this.maxControl.setValue(+this.globalDefault?.min, {emitEvent: false});
        }

        if (maxValue !== null && maxValue < +this.localDefault?.min) {
            this.maxControl.setValue(+this.localDefault?.min, {emitEvent: false});
        }

        if (maxValue !== null && maxValue > +this.globalDefault?.max) {
            this.maxControl.setValue(+this.globalDefault?.max, {emitEvent: false});
        }

        if (maxValue !== null && maxValue > +this.localDefault?.max) {
            this.maxControl.setValue(+this.localDefault?.max, {emitEvent: false});
        }
    }

    private completeValues() {
        if (this.minControl.value === null) this.minControl.setValue(this.maxControl.value);

        if (this.maxControl.value === null) this.maxControl.setValue(this.minControl.value);

        if (
            this.minControl.value !== null &&
            this.maxControl.value !== null &&
            this.maxControl.value < this.minControl.value
        ) {
            this.maxControl.setValue(this.minControl.value, {emitEvent: false});
        }

        if (
            this.maxControl.value !== null &&
            this.minControl.value !== null &&
            this.minControl.value > this.maxControl.value
        ) {
            this.minControl.setValue(this.maxControl.value, {emitEvent: false});
        }

        if (this.minControl.value === null && this.maxControl.value === null) {
            this.inputControl.reset();
        } else {
            let result = `${this.minControl.value} — ${this.maxControl.value}`;

            this.inputControl.setValue(result);
        }
    }

    reset() {
        this.minControl.reset();
        this.maxControl.reset();
    }
}
